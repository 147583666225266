<template>
  <loader :isLoading="clientLoading" />
  <div class="max-w-screen-md">
    <lf-card v-if="!clientLoading" class="py-6 mb-8">
      <template v-slot>
        <loader :isLoading="isSubmitting" />
        <div>
          <access-control :roles="[SUPER_ADMIN, ADMIN]">
            <div class="border-b pb-6 pt-2">
              <lf-switch
                name="data.stop_experian_dnb_services"
                v-model="allowServices"
                @toggleChanged="handleStopExperianDnb"
              >
                <lf-h3>
                  {{ $t("ORGANIZATION.BUSINESS_CREDIT_DATA.ALLOW_APPS_DATA") }}
                </lf-h3>
                <span>
                  {{ $t("ORGANIZATION.BUSINESS_CREDIT_DATA.GIVE_ACCESS") }}
                </span>
              </lf-switch>
            </div>
          </access-control>
          <div class="mt-6">
            <div class="mb-6">
              <lf-h3>
                {{
                  isAdmin
                    ? $t("ORGANIZATION.BUSINESS_CREDIT_DATA.TITLE")
                    : $t(
                        "ORGANIZATION.BUSINESS_CREDIT_DATA.SERVICES_SUBSCRIBED"
                      )
                }}
              </lf-h3>
              <span v-if="isAdmin">
                {{
                  $t("ORGANIZATION.BUSINESS_CREDIT_DATA.CONFIGURE_ADDITIONAL")
                }}
              </span>
            </div>
            <template
              v-if="
                (isAdmin || !client.stop_experian_dnb_services) &&
                !(isFundingAdvisor || isClientFundingAdvisor)
              "
            >
              <access-control :roles="[ADMIN, SUPER_ADMIN]">
                <div
                  v-for="(
                    serviceChildren, serviceName
                  ) in businessCreditServices"
                  :key="serviceName"
                >
                  <div class="mb-2 flex">
                    <lf-checkbox
                      :id="serviceName"
                      name="selectedServices"
                      v-if="Object.values(serviceChildren).length === 1"
                      v-model="selectedServices"
                      :value="Object.keys(serviceChildren)[0]"
                    />
                    <!-- had to use custom this checbkox instead of lf one because of the styling -->
                    <input
                      class="form-checkbox text-indigo-600 h-4 w-4 border-gray-300 rounded-sm cursor-pointer"
                      type="checkbox"
                      :id="`${serviceName}`"
                      v-if="Object.values(serviceChildren).length > 1"
                      v-model="selectAll[serviceName]"
                      @click="
                        (e) => handleServiceSelectAll(e, `${serviceName}`)
                      "
                      :value="Object.keys(serviceChildren)[0]"
                    />
                    <label
                      :for="`${serviceName}`"
                      class="ml-2 cursor-pointer"
                      :title="
                        $t(
                          'ORGANIZATION.BUSINESS_CREDIT_DATA.CONFIGURE_SERVICE',
                          {
                            service: serviceName
                          }
                        )
                      "
                    >
                      {{ serviceName }}
                    </label>
                  </div>
                  <div
                    class="flex flex-col mt-4"
                    v-if="Object.values(serviceChildren).length > 1"
                  >
                    <lf-checkbox
                      class="ml-8 mb-4"
                      name="data.has_middesk"
                      v-for="(item, itemKeyName) in serviceChildren"
                      :key="itemKeyName"
                      v-model="selectedServices"
                      :value="itemKeyName"
                    >
                      {{ item }}
                    </lf-checkbox>
                  </div>
                </div>
              </access-control>
              <access-control
                :roles="[...CLIENT_ROLE_GROUP, FUNDING_ADVISOR, UNDERWRITER]"
              >
                <template v-if="selectedServices.length">
                  <div
                    v-for="(
                      serviceChildren, serviceName
                    ) in businessCreditServices"
                    :key="serviceName"
                  >
                    <lf-h4
                      class="mt-4"
                      v-if="canRenderService(`${serviceName}`)"
                    >
                      <span class="text-checkmark-green mr-2">
                        <icon-base class="" icon="success-round-big" />
                      </span>
                      {{ serviceName }}
                    </lf-h4>
                    <div
                      class="ml-10 mt-2 flex flex-col"
                      v-if="Object.values(serviceChildren).length > 1"
                    >
                      <template
                        v-for="(child, key) in serviceChildren"
                        :key="key"
                      >
                        <span v-if="showServiceChild(key)">
                          {{ child }}
                        </span>
                      </template>
                    </div>
                  </div>
                </template>
                <div v-else>
                  <business-credit-contact />
                </div>
              </access-control>
            </template>
            <template v-else>
              <div class="my-8">
                <business-credit-contact />
              </div>
            </template>
          </div>
          <access-control :roles="[ADMIN, SUPER_ADMIN]">
            <div
              class="flex justify-end items-center min-w-full border-t pt-6 pr-5"
            >
              <primary-button
                type="button"
                @click="submit"
                :disabled="isSubmitting"
              >
                {{ $t("COMMON.SAVE_CHANGES") }}
              </primary-button>
            </div>
          </access-control>
        </div>
      </template>
    </lf-card>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useAuth } from "@/hooks/auth";
import { useRoute } from "vue-router";
import {
  CLIENT_ROLE_GROUP,
  SUPER_ADMIN,
  ADMIN,
  UNDERWRITER,
  FUNDING_ADVISOR,
  EXPERIAN_BUSINESS_OWNER_PROFILE
} from "@/helpers/constants";

import LfCheckbox from "../../../components/ui/inputs/LfCheckbox.vue";
import BusinessCreditContact from "./BusinessCreditContact.vue";
import type { IClient } from "@/models/clients";
import type {
  BusinessCreditService,
  IBusinessCreditServices
} from "@/models/options";
import { usePromiseWrapper } from "@/hooks/common";

type BusinessCreditServices = Omit<
  IBusinessCreditServices,
  typeof EXPERIAN_BUSINESS_OWNER_PROFILE
>;

const legacyServices: BusinessCreditService[] = [
  "mx",
  "ntropy",
  "lexis_nexis_small_business_blended_credit_score"
];

const emit = defineEmits(["updated"]);
const { getters, dispatch } = useStore();
const { isFundingAdvisor, isAdmin, isUnderwriter, isClientFundingAdvisor } =
  useAuth();
const route = useRoute();

const client = computed<IClient>(() => ({
  ...getters[
    route.name === "ClientOrganization"
      ? "auth/authClientSettings"
      : "clients/active"
  ]
}));

const allowServices = ref(!client.value.stop_experian_dnb_services);
const selectedServices = ref(client.value.business_credit_services || []);
const selectAll = ref<Record<string, boolean>>({});

const businessCreditServices = computed<BusinessCreditServices>(() => {
  const services = getters[
    "options/businessCreditServices"
  ] as IBusinessCreditServices;
  return Object.fromEntries(
    Object.entries(services).filter(
      ([key]) => !key.includes(EXPERIAN_BUSINESS_OWNER_PROFILE)
    )
  ) as BusinessCreditServices;
});

const updateAction = computed(() =>
  route.name === "ClientOrganization"
    ? "auth/updateAuthClientSettings"
    : "clients/updateClient"
);

const clientLoading = computed(() => getters["clients/clientsLoading"]);

const getServiceValues = (serviceName: keyof BusinessCreditServices) =>
  Object.keys(
    businessCreditServices.value[serviceName]
  ) as BusinessCreditService[];

const assignServiceAllSelected = (
  serviceName: keyof BusinessCreditServices
) => {
  const serviceValues = getServiceValues(serviceName);
  selectAll.value[serviceName] = serviceValues.every((service) =>
    selectedServices.value?.includes(service)
  );
};

const handleServiceSelectAll = (
  e: MouseEvent,
  serviceName: keyof BusinessCreditServices
) => {
  const value = !!(e.target as HTMLInputElement)?.checked;
  const serviceValues = getServiceValues(serviceName);
  selectedServices.value = value
    ? [...new Set([...selectedServices.value, ...serviceValues])]
    : selectedServices.value.filter(
        (service) => !serviceValues.includes(service)
      );
};

const canRenderService = (serviceName: keyof BusinessCreditServices) =>
  Object.keys(businessCreditServices.value[serviceName]).some((service) =>
    selectedServices.value.includes(service as BusinessCreditService)
  );

const showServiceChild = (key: string | number) =>
  selectedServices.value.includes(key as BusinessCreditService);

const { loading: isSubmitting, fetchWrapper: submit } = usePromiseWrapper(
  async () => {
    if (isUnderwriter || isFundingAdvisor || isClientFundingAdvisor) {
      return;
    }
    const businessCreditServices = [...selectedServices.value].filter(
      (service) => !legacyServices.includes(service)
    );
    const data = {
      business_credit_services: businessCreditServices
    };
    const response = await dispatch(
      updateAction.value,
      route.name === "ClientOrganization" ? data : { id: client.value.id, data }
    );
    if (response?.business_credit_services) {
      selectedServices.value = response.business_credit_services;
    }
    emit("updated");
  }
);

const handleStopExperianDnb = async (val: boolean) => {
  if (!isAdmin) {
    return;
  }
  const stop_experian_dnb_services = !val;
  const payload =
    route.name === "ClientOrganization"
      ? { stop_experian_dnb_services }
      : { data: { stop_experian_dnb_services } };
  await dispatch(updateAction.value, payload);
};

const runAssignment = () => {
  Object.keys(businessCreditServices.value).forEach((service) => {
    assignServiceAllSelected(service as keyof BusinessCreditServices);
  });
};

watch(selectedServices, runAssignment);

watch(
  () => client.value.business_credit_services,
  (val) => {
    selectedServices.value = val;
  }
);

Object.keys(businessCreditServices.value).forEach((service) => {
  selectAll.value[service] = false;
});
runAssignment();
</script>
